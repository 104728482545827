import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Logos from '../components/logos'
import Spacer from '../components/Spacer'
import Acc from '../components/acc'
import '../styles/home.css'


const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <div pageTitle="Home Page" className='mainpage'>
        <div className='landinggrid homegrid'>
          <div className='landingwhitespace'>

          </div>
          <div className="landingwords valuewords">
            <p>COLLABORATIVE.</p>
            <p>INNOVATIVE.</p>
            <p>RELIABLE.</p>
          </div>

          <StaticImage
            alt="A tall building"
            src="../images/homefinal.png"
            className="image landingimage" />

        </div>

        <Logos className="logos"></Logos>

        <Spacer classes="h50" />
        <h1 className='homebelief text'>We believe in carving powerful experiences through creative designs and efficient execution.  </h1>
        <Spacer classes="h50" />

        <div className="homegrid2">
          <StaticImage
            alt="Clifford, a reddish-brown pitbull, dozing in a bean bag chair"
            src="../images/Buildcon.jpg"
            className="image" />

          <h2 className='text'>ANA is a Pune-based Architecture firm with a legacy of over 35 years, and expertise of 2000+ projects.</h2>
        </div>
        <Acc></Acc>

        <Spacer classes="h50" />

        <StaticImage
          alt="A elegant skyscraper"
          src="../images/home4.png"
          className="image fullwidth mobilecropped" />

        <Spacer classes="h50" />
        <h2 className='text hometext4'>
          Dive into our work and explore our projects here to get a gist of our style, process, and execution.
        </h2>

        <Spacer classes="h19" />

        <Link to='/portfolio/kalpatru/' aria-label="Portfolio Link">
          <StaticImage
            alt="kalpatru"
            src="../images/f-building16.png"
            className="image pfimage1" />
        </Link>

        <Spacer classes="h19" />

        <Link to='/portfolio/truspace-balewadi/' aria-label="truspace balewadi">
          <StaticImage
            alt="truspace balewadi"
            src="../images/1488-TruSpace-Balewadi Buildibng_Cam 004.jpg"
            className="image pfimage2" />
        </Link>

        <Spacer classes="h19" />

        <Link to='/portfolio/' aria-label="Portfolio Link" className='centerthis'>
          <StaticImage
            alt="Portfolio"
            src="../images/porftoliohome.jpeg"
            className="image pfimage3" />
        </Link>

        <Link to='/portfolio/'>
          <p className='text hometext5 buttontext'>
            View More
          </p>
        </Link>
        <Spacer classes="h50" />


      </div>
      
    </Layout>
  )
}

export const Head = () => <Seo title="Home" />

export default IndexPage
